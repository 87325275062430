import React from 'react'
import { useAuth } from '../contexts/AuthContext'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { useUserCon } from '../contexts/UserContext'
import logo from '../images/logoSVG.svg'

export default function Navigationbar () {
  const { currentUser } = useAuth()
  const { isAdmin } = useUserCon()
  const history = useHistory()

  // Check if a user is logged into the page. If not, do not render the navbar.
  if (currentUser == null) {
    return (
      <>
      </>
    )
  }

  return (
    <Navbar bg='light' style={{position: 'sticky', top: '0', zIndex: '9001'}}>
      <Container style={{ minWidth: '100%' }}>
        <Navbar.Brand onClick={() => history.push('/')}>
          <img
            src={logo}
            height='30'
            className='d-inline-block allign-top'
            alt='Chomskylab logo' />
        </Navbar.Brand>
        <Nav className='me-auto'>
          <Nav.Link onClick={() => history.push('/')}>Home</Nav.Link>
          {isAdmin ? <Nav.Link onClick={() => history.push('/users')}>Users</Nav.Link> : null}
          {isAdmin ? <Nav.Link onClick={() => history.push('/add-component')}>Add Component</Nav.Link> : null}
          {isAdmin ? <Nav.Link onClick={() => history.push('/inventory')}>Inventory Status</Nav.Link> : null}
        </Nav>
        <Navbar.Collapse className='justify-content-end'>
          <Navbar.Text>
            Signed in as: <Link to='/user-profile'>{currentUser.email}</Link>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
